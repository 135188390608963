export default function CircularButton(props){
    const bgVariants = {
        "dull-yellow": "bg-dull-yellow",
    };

    return (
    <button className={`${props.className || ""} ${bgVariants[props.bg] || ""} shadow-md rounded-[50%] p-[1rem] hover:scale-105 duration-300 ease-in-out w-max h-max bg-white top-[1rem] right-[1.5rem] flex items-center justify-center`} onClick={props.onClick}>
        {props.content}
    </button>
    );
}