import Clock from "./img/clock"
export default function StepNoteSection(){
    return (
        <div className='step-note-section flex flex-col text-[11px]'>
            <div className='flex'>
            <p className='waiting-text text-neutral-strong-blue mr-[1rem]'>Waiting for ___ reason.</p>
            <div className="estimated-time-left-div flex">
                <Clock color="dull-yellow" />
                <p className='estimated-time-left-p text-dull-yellow'>Estimated time: 24-48 hours.</p>
            </div>
            </div>
            <div className="step-info-div flex">
                <p className='step-info-p text-status-info-default-blue'>Info: Please prepare for action “________”</p>
            </div>
        </div>
    )
}