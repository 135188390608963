// src/components/GuestAnswerAdditionalQuestions.jsx

import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSocket } from "../context/SocketContext";

const GuestAnswerAdditionalQuestions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const businessName = location.state?.businessName || "Your Business";
  const formId = location.state?.formId;

  const { socket } = useSocket();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    question1: "",
    question2: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.question1)
      newErrors.question1 = "Loan Officer name and title is required";
    if (!formData.question2)
      newErrors.question2 = "Loan document date is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    if (socket && formId) {
      const updatedItem = {
        _id: formId,
        progress_step: 11,
        answers: formData,
      };
      socket.emit("update_items", { items: [updatedItem] }, (response) => {
        console.log(
          "[GuestAnswerAdditionalQuestions] Update Items Response:",
          response
        );
        navigate("/guest-signature-emails", {
          state: {
            formId: formId,
            businessName: businessName
          }
        });
      });
    } else {
      console.error(
        "[GuestAnswerAdditionalQuestions] Socket or formId is not available"
      );
      navigate("/guest-dynamic-table");
    }
    setIsSubmitting(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 transition-colors mb-8"
        >
          <img src="/images/ArrowLeft.svg" className="w-4 h-4" alt="Back" />
          <span>Back</span>
        </button>

        <div className="bg-white rounded-lg shadow-sm p-6 sm:p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">
            Answer Additional Questions
          </h1>

          <div className="space-y-4 mb-8">
            <p className="text-lg text-gray-700">
              Hello, Congratulations on your{" "}
              <span className="font-semibold">{businessName}</span> Loan
              Approval.
            </p>
            <p className="text-gray-600">
              Please answer these additional questions to proceed.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  1. The Name and Title of the Loan Officer that will sign the
                  Nor-Cal Documents
                </label>
                <input
                  type="text"
                  name="question1"
                  value={formData.question1}
                  onChange={handleChange}
                  className={`w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                    errors.question1 ? "border-red-500" : "border-gray-300"
                  }`}
                />
                {errors.question1 && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.question1}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  2. Date of the loan documents
                </label>
                <input
                  type="date"
                  name="question2"
                  value={formData.question2}
                  onChange={handleChange}
                  className={`w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                    errors.question2 ? "border-red-500" : "border-gray-300"
                  }`}
                />
                {errors.question2 && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.question2}
                  </p>
                )}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full sm:w-auto px-6 py-3 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors
                ${
                  isSubmitting
                    ? "bg-blue-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
            >
              {isSubmitting ? "Submitting..." : "Submit Answers"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestAnswerAdditionalQuestions;
