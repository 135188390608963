import React, { useState, useEffect, useRef } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTheme } from '../context/ThemeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSocket } from '../context/SocketContext';
import axios from 'axios';
import RectButton from './RectButton';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const GuestPDFViewer = () => {
    const location = useLocation();
    const { pdfBase64: initialPdfUrl, jsonData: initialJsonData, iframe: initialIframeData, formId: initialFormId } = location.state || {};
    const navigate = useNavigate();

    const [pdfUrl, setPdfUrl] = useState();
    const [pdfBase64, setPdfBase64] = useState(initialPdfUrl || null);
    const [jsonData, setJsonData] = useState([]);
    const [formId, setFormId] = useState(initialFormId || null);
    const [googleDocsUrl, setGoogleDocsUrl] = useState(initialIframeData || "https://docs.google.com/document/d/1YXu_Uu6Z5hdjqjRUX71PU1D_KPiKWe1Q/edit?usp=sharing&ouid=103844593516674711347&rtpof=true&sd=true");
    const [highlightedAreas, setHighlightedAreas] = useState([]);
    const [temporaryHighlights, setTemporaryHighlights] = useState([]);
    const [isHighlightVisible, setIsHighlightVisible] = useState(true);
    const [showAllHighlights, setShowAllHighlights] = useState(true);
    const { darkMode, toggleTheme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isApiLoading, setIsApiLoading] = useState(false);
    const { socket, isConnected } = useSocket();
    const [isSplitScreen, setIsSplitScreen] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const themeStyles = {
        backgroundColor: darkMode ? '#121212' : '#FFFFFF',
        color: darkMode ? 'white' : 'black',
        borderColor: darkMode ? '#1e1e1e' : '#CCCCCC',
        buttonBackgroundColor: '#6A55FE'
    };

    const zoomPluginInstance = zoomPlugin();
    const highlightPluginInstance = highlightPlugin({
        renderHighlights: (props) => {
            return (
                <div>
                    {showAllHighlights && highlightedAreas.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: '#2183F6',
                                    opacity: 0.5,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                    {temporaryHighlights.map((area, idx) => (
                        area.pageIndex === props.pageIndex && (
                            <div
                                key={idx}
                                style={{
                                    background: '#2183F6',
                                    opacity: isHighlightVisible ? 0.5 : 0,
                                    position: 'absolute',
                                    top: `${area.top * 100}%`,
                                    left: `${area.left * 100}%`,
                                    height: `${area.height * 100}%`,
                                    width: `${area.width * 100}%`,
                                    pointerEvents: 'none',
                                    transform: `rotate(${props.rotation}deg)`,
                                    transition: 'opacity 2s'
                                }}
                            />
                        )
                    ))}
                </div>
            );
        },
    });

    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;

    useEffect(() => {
        if (pdfBase64) {
            try {
                const byteCharacters = atob(pdfBase64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });

                // Create object URL from Blob
                const objectUrl = URL.createObjectURL(blob);
                setPdfUrl(objectUrl);

                // Clean up the object URL when the component unmounts
                return () => URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error('Error converting base64 to Blob:', error);
            }
        }
    }, [pdfBase64]);

    useEffect(() => {
        if (initialPdfUrl && initialJsonData) {
            setJsonData(initialJsonData.documents[0].fields);
            extractAndHighlightFromJson(initialJsonData.documents[0].fields);
        }
    }, [initialPdfUrl, initialJsonData]);

    useEffect(() => {
        // Socket event listeners
        const handleFormCopilotResponse = (response) => {
            console.log('[custom pdf viewer] form copilot response received');
            if (response && response.documents && response.documents[0] && response.documents[0].fields) {
                setJsonData(response.documents[0].fields);
                extractAndHighlightFromJson(response.documents[0].fields);
                
                // Emit the new loan notification after receiving form copilot response
                socket.emit('send_email_new_loan_notification');
            } else {
                console.error('Invalid response structure:', response);
                setJsonData({ error: 'Invalid response structure' });
            }
            // Clear loading states
            setIsProcessing(false);
            setLoading(false);
        };

        const handleError = (error) => {
            console.error('Socket error:', error.msg);
            setJsonData({ error: error.msg });
            // Clear loading states
            setIsProcessing(false);
            setLoading(false);
        };

        // Add event listener for email sent confirmation
        const handleEmailSent = (response) => {
            console.log('New loan notification email sent:', response.msg);
        };

        // Add event listeners
        socket.on('form_copilot_response', handleFormCopilotResponse);
        socket.on('error', handleError);
        socket.on('email_sent', handleEmailSent);

        // Cleanup function
        return () => {
            socket.off('form_copilot_response', handleFormCopilotResponse);
            socket.off('error', handleError);
            socket.off('email_sent', handleEmailSent);
        };
    }, [socket]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Set loading states immediately when file is selected
        setIsProcessing(true);
        setLoading(true);

        const fileUrl = URL.createObjectURL(file);
        setPdfUrl(fileUrl);
        setFileName(file.name);

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            const base64Content = base64String.split(',')[1];
            const isDivisibleBy4 = base64Content.length % 4 === 0;
            let paddedBase64Content = base64Content;
            if (!isDivisibleBy4) {
                const missingPadding = 4 - (base64Content.length % 4);
                paddedBase64Content += '='.repeat(missingPadding);
            }
            const base64StringWithPadding = `${base64String.split(',')[0]},${paddedBase64Content}`;
            console.log('[CustomPDFViewer] emitting form_copilot with formId: ', formId);

            if (!socket) {
                console.error('Socket not connected');
                setIsProcessing(false);
                setLoading(false);
                return;
            }

            // Emit the form_copilot event
            socket.emit('form_copilot', {
                data: {
                    body: base64StringWithPadding,
                    formId: formId,
                },
            });
        };

        reader.onerror = error => {
            console.error('Error reading file:', error);
            setJsonData({ error: error.toString() });
            setIsProcessing(false);
            setLoading(false);
        };

        reader.readAsDataURL(file);
    };

    const extractAndHighlightFromJson = (jsonData) => {
        const highlightedAreas = [];

        Object.entries(jsonData).forEach(([key, field]) => {
            if (field.boundingRegions) {
                field.boundingRegions.forEach(region => {
                    const polygon = region.polygon;
                    const [x1, y1, x2, y2, x3, y3, x4, y4] = polygon;
                    const left = Math.min(x1, x2, x3, x4);
                    const top = Math.min(y1, y2, y3, y4);
                    const width = Math.max(x1, x2, x3, x4) - left;
                    const height = Math.max(y1, y2, y3, y4) - top;

                    highlightedAreas.push({
                        top: top / 11,
                        left: left / 8.5,
                        height: height / 11,
                        width: width / 8.5,
                        pageIndex: region.pageNumber - 1,
                        fieldKey: key,
                    });
                });
            }
        });

        setHighlightedAreas(highlightedAreas);
    };

    const handlePercentages = (x) => {
        return Math.round(x);
    };

    const handlePanelClick = (boundingRegions) => {
        setShowAllHighlights(false);
        setHighlightedAreas([]);
        if (boundingRegions && boundingRegions.length > 0) {
            const region = boundingRegions[0];
            const pageIndex = region.pageNumber - 1;
            jumpToPage(pageIndex);

            const [x1, y1, x2, y2, x3, y3, x4, y4] = region.polygon;
            const left = Math.min(x1, x2, x3, x4);
            const top = Math.min(y1, y2, y3, y4);
            const width = Math.max(x1, x2, x3, x4) - left;
            const height = Math.max(y1, y2, y3, y4) - top;

            const highlightedArea = {
                top: top / 11,
                left: left / 8.5,
                height: height / 11,
                width: width / 8.5,
                pageIndex: pageIndex,
            };

            setTemporaryHighlights([highlightedArea]);
            setIsHighlightVisible(true);
            setTimeout(() => {
                setIsHighlightVisible(false);
                setTimeout(() => {
                    setTemporaryHighlights([]);
                }, 2000);
            }, 1000);
        }
    };

    const toggleShowAllHighlights = () => {
        setShowAllHighlights(!showAllHighlights);
    };

    useEffect(() => {
        if (showAllHighlights) {
            setTemporaryHighlights([]);
            setIsHighlightVisible(false);
        }
    }, [showAllHighlights]);

    const handleApiCall = () => {
        console.log('updating google docs');
        setIsApiLoading(true);
        const dataToSend = Object.entries(jsonData).map(([key, field]) => ({
            title: key,
            content: field.valueString
        }));

        axios({
            method: 'post',
            url: 'https://api.formcopilot.app/generate_google_docs',
            data: {
                formId: formId,
                dataToSend,
            },
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true // Ensure cookies are sent with the request
        })
            .then(response => {
                if (response.data && response.data.shareableLink) {
                    setGoogleDocsUrl(response.data.shareableLink);
                }
                setIsApiLoading(false);
            })
            .catch(error => {
                console.error('Error making API call:', error);
                setIsApiLoading(false);
            });
    };

    const handleDownloadPdf = () => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = fileName || 'Credit Memo.pdf';
            link.click();
        }
    };

    function toggleSplitScreen() {
        setIsSplitScreen(!isSplitScreen);
    }

    const allDerivedClassNames = {
        mainPageToolbarClassname: "main-page-toolbar",
        memoDetailsSectionClassname: "memo-details-section",
    }

    const mainPageToolbar = (
        <div className='flex justify-between items-center'>
            <div>
                <button className='w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]'
                    onClick={() => navigate('/dynamic-table')}
                >
                    <img src="/images/ArrowLeft.svg" width={24} height={24} alt="Go Back" />
                </button>
            </div>
            <div className='flex items-center gap-2 p-2 ps-0'>
                <RectButton
                    className="split-screen-button"
                    onClick={toggleSplitScreen}
                    content={
                        <div className="flex items-center gap-1">
                            {isSplitScreen ? (
                                <>
                                    <p>Exit Full Screen</p>
                                    <img src="/images/Minimize.svg" width={16} height={16} alt="Exit Full Screen" />
                                </>
                            ) : (
                                <>
                                    <p>Full Screen</p>
                                    <img src="/images/Maximize.svg" width={16} height={16} alt="Full Screen" />
                                </>
                            )}
                        </div>
                    }
                />
                <label htmlFor="file-upload" className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] mr-1 flex items-center gap-1'>
                    {!isSplitScreen && <span>Upload Document</span>}
                    <img src="/images/FileArrowUp.svg" width={16} height={16} alt="UploadFile" />
                </label>
                <input
                    id="file-upload"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="application/pdf"
                    required
                />
                <button onClick={toggleShowAllHighlights} className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] flex items-center gap-1' >
                    {!isSplitScreen && (showAllHighlights ? 'Hide Highlights' : 'Show Highlights')}
                    <img src="/images/Eye.svg" width={16} height={16} alt="EyeIcon" />
                </button>
                <button onClick={handleApiCall}
                    className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] relative mx-1 flex items-center gap-1'>
                    {isApiLoading ? (
                        <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                        <>
                            {!isSplitScreen && <span>Populate Nor-Cal Template</span>}
                            <img src="/images/PencilSimple.svg" width={16} height={16} alt="PencilIcon" />
                        </>
                    )}
                </button>
                <button onClick={handleDownloadPdf}
                    className='hover:scale-105 duration-300 ease-in-out cursor-pointer bg-white text-[#2183F6] font-[500] px-[20px] py-[10px] rounded-[4px] relative mx-1 flex items-center gap-1'>
                    {!isSplitScreen && <span>Download PDF</span>}
                    <img src="/images/FileArrowDown.svg" width={16} height={16} alt="Download" />
                </button>
                <button className='w-[44px] h-[44px] bg-white flex items-center justify-center rounded-[4px]'>
                    <img src="/images/HouseSimple.svg" width={24} height={24} alt="Go Back" />
                </button>
            </div>
        </div>
    );

    // Add ProcessingOverlay component
    const ProcessingOverlay = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto mb-4"></div>
                <h2 className="text-xl font-semibold mb-2">Processing Document</h2>
                <p className="text-gray-600">Please wait while we analyze your document...</p>
            </div>
        </div>
    );

    return (
        <div className="flex h-screen bg-gray-50">
            {isProcessing && <ProcessingOverlay />}
            <iframe
                src={googleDocsUrl}
                className={`h-full border-none ${isSplitScreen ? 'w-full' : 'w-[46%]'}`}
                title="Google Document"
            ></iframe>
            <div className="flex-1 p-5 relative">
                {mainPageToolbar}
                {pdfUrl && (
                    <div className="flex h-[95vh] relative w-full">
                        <div className="min-w-[700px] bg-white rounded-lg shadow-sm">
                            <Worker workerUrl={pdfjsWorker}>
                                <div className="h-full w-full">
                                    <Viewer
                                        fileUrl={pdfUrl}
                                        plugins={[zoomPluginInstance, highlightPluginInstance, pageNavigationPluginInstance]}
                                        onDocumentLoad={() => console.log('Document loaded')}
                                        defaultScale={.95}
                                    />
                                </div>
                            </Worker>
                        </div>
                        {!isSplitScreen && (
                            <div className="w-[370px] ml-4">
                                <div className="h-[110vh] overflow-y-auto px-5">
                                    {jsonData ? (
                                        <>
                                            {Object.entries(jsonData).map(([key, value], index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handlePanelClick(value.boundingRegions)}
                                                    className="p-4 bg-white rounded-lg shadow-sm mb-2 cursor-pointer hover:shadow-md transition-shadow duration-200"
                                                >
                                                    <div className="flex items-center justify-between">
                                                        <h4 className="capitalize text-gray-600 m-0">{key}</h4>
                                                        <p className="text-gray-800 font-medium text-sm">
                                                            {handlePercentages(value.confidence * 100)}%
                                                        </p>
                                                    </div>
                                                    <p className="text-sm font-semibold text-gray-800 mt-1">
                                                        {value.valueString}
                                                    </p>
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="flex items-center justify-center w-full h-full">
                                            {loading ? (
                                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
                                            ) : (
                                                <h2 className="text-gray-500 text-lg font-medium">Please Upload Document</h2>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default GuestPDFViewer;
