import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useReducer,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useSocket } from "../context/SocketContext";
import { useQueryClient } from "@tanstack/react-query";
import { axiosInstance } from "../utils/queryClient";
import { BackButton, AnswerQuestionsButton } from "./ui/button";
import { EmptyPdfState, EmptyUploadState, FileList } from "./ui/upload";
import { toast } from "react-hot-toast";
// import DocuSign from "docusign-esign";

// Constants
const INITIAL_STEP = 2;
const FILE_TYPES = {
  PDF: "application/pdf",
};
const ACTIONS = {
  SET_INITIAL_DATA: "SET_INITIAL_DATA",
  SET_STEPS: "SET_STEPS",
  SET_SHOW_POPUP: "SET_SHOW_POPUP",
  UPDATE_PROGRESS: "UPDATE_PROGRESS",
  SET_PDF_DATA: "SET_PDF_DATA",
};

// Helpers
const convertBase64ToPdfUrl = (base64String) => {
  if (!base64String) return null;

  try {
    const byteArray = Uint8Array.from(atob(base64String), (char) =>
      char.charCodeAt(0)
    );
    return URL.createObjectURL(new Blob([byteArray], { type: FILE_TYPES.PDF }));
  } catch (error) {
    toast.error("Failed to render PDF");
    console.error("PDF render failed:", error);
    return null;
  }
};

// Custom Hooks
const useInitialState = (locationState) => {
  if (!locationState) return null;

  const {
    pdfBase64: initialPdfUrl,
    jsonData: initialJsonData,
    iframe: initialIframeData,
    formId: initialFormId,
    progressStep: initialProgressStep,
    additionalData: initialAdditionalData,
    pdfBase64NorCalTemplate,
    docAiResultsNorCalTemplate,
    loanName: loan_name,
  } = locationState;

  return {
    initialData: {
      pdfBase64: initialPdfUrl,
      jsonData: initialJsonData,
      iframe: initialIframeData,
      formId: initialFormId,
      progressStep: initialProgressStep || INITIAL_STEP,
      additionalData: initialAdditionalData,
      pdfBase64NorCalTemplate,
      docAiResultsNorCalTemplate,
      loan_name,
    },
    steps: [
      { id: 0, key: "0", title: "None", completed: true },
      {
        id: 1,
        key: "1",
        title: "Receive Bank Credit Memo via Email.",
        completed: true,
      },
      {
        id: 2,
        key: "2",
        title: "Upload Bank Credit Memo to Create NorCal Credit Memo",
        completed: false,
        action: "pdf-viewer",
      },
    ],
    showPopup: false,
  };
};

// Add after useInitialState hook
function reducer(state, action) {
  switch (action.type) {
    case "SET_INITIAL_DATA":
      return {
        ...state,
        initialData: {
          ...state.initialData,
          ...action.payload,
        },
      };
    case "SET_STEPS":
      return {
        ...state,
        steps: action.payload,
      };
    case "SET_SHOW_POPUP":
      return {
        ...state,
        showPopup: action.payload,
      };
    case "UPDATE_PROGRESS":
      return {
        ...state,
        initialData: {
          ...state.initialData,
          progressStep: action.payload,
        },
      };
    case "SET_PDF_DATA":
      return {
        ...state,
        initialData: {
          ...state.initialData,
          pdfBase64: action.payload.pdfBase64,
          jsonData: action.payload.jsonData,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const useFileUpload = (state, queryClient, dispatch, setAdditionalFiles) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("loan_id", state.initialData.formId);
    formData.append("step", state.initialData.progressStep);
    formData.append("part", "additional_files");

    try {
      const { data } = await axiosInstance.post("/files/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (!data.success) throw new Error(data.message || "Upload failed");

      return {
        success: true,
        message: data.message,
        data: {
          key: data.data.key,
          url: data.data.url,
        },
      };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Network error occurred";
      toast.error(`Upload failed: ${errorMessage}`);
      return { success: false, message: errorMessage };
    }
  };

  const deleteFile = async (fileKey) => {
    try {
      const { data } = await axiosInstance.delete("/files/delete", {
        params: {
          loan_id: state.initialData.formId,
          step: state.initialData.progressStep,
          part: "part_1_additional",
          key: fileKey,
        },
      });

      if (!data.success) throw new Error(data.message || "Delete failed");

      return data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Network error occurred";
      toast.error(`Delete failed: ${errorMessage}`);
      throw new Error(errorMessage);
    }
  };

  const handleFileInputChange = async (event) => {
    if (!event.target.files?.length) return;

    const files = Array.from(event.target.files);
    const filesWithUrls = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      status: "uploading",
    }));

    const currentLength = uploadedFiles.length;
    setUploadedFiles((prev) => [...prev, ...filesWithUrls]);

    for (let i = 0; i < filesWithUrls.length; i++) {
      try {
        const response = await uploadFile(filesWithUrls[i].file);
        setUploadedFiles((prev) =>
          prev.map((f, index) =>
            index === currentLength + i
              ? {
                  ...f,
                  status: response.success ? "completed" : "error",
                  fileKey: response.success ? response.data.key : undefined,
                  fileUrl: response.success ? response.data.url : undefined,
                  fileName: f.file.name,
                  error: response.success ? undefined : response.message,
                }
              : f
          )
        );
      } catch (error) {
        const errorMessage = error.message || "Unknown error";
        toast.error(`Upload failed: ${errorMessage}`);
        setUploadedFiles((prev) =>
          prev.map((f, index) =>
            index === currentLength + i
              ? { ...f, status: "error", error: errorMessage }
              : f
          )
        );
      }
    }

    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleFileDrop = async (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const filesWithUrls = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      status: "uploading",
    }));

    const currentLength = uploadedFiles.length;
    setUploadedFiles((prev) => [...prev, ...filesWithUrls]);

    for (let i = 0; i < filesWithUrls.length; i++) {
      try {
        const response = await uploadFile(filesWithUrls[i].file);
        setUploadedFiles((prev) =>
          prev.map((f, index) =>
            index === currentLength + i
              ? {
                  ...f,
                  status: response.success ? "completed" : "error",
                  fileKey: response.success ? response.data.key : undefined,
                  fileUrl: response.success ? response.data.url : undefined,
                  fileName: f.file.name,
                  error: response.success ? undefined : response.message,
                }
              : f
          )
        );
      } catch (error) {
        const errorMessage = error.message || "Unknown error";
        toast.error(`Upload failed: ${errorMessage}`);
        setUploadedFiles((prev) =>
          prev.map((f, index) =>
            index === currentLength + i
              ? { ...f, status: "error", error: errorMessage }
              : f
          )
        );
      }
    }
  };

  const removeFile = async (index) => {
    const fileToRemove = uploadedFiles[index];
    if (!fileToRemove.fileKey) {
      setUploadedFiles((prev) => {
        const newFiles = prev.filter((_, idx) => idx !== index);
        URL.revokeObjectURL(fileToRemove.url);
        return newFiles;
      });
      return;
    }

    toast.promise(
      (async () => {
        try {
          const response = await deleteFile(fileToRemove.fileKey);
          if (response.success) {
            setUploadedFiles((prev) => {
              const newFiles = prev.filter((_, idx) => idx !== index);
              URL.revokeObjectURL(fileToRemove.url);
              return newFiles;
            });
          } else {
            throw new Error(response.message || "Failed to delete file");
          }
        } catch (error) {
          console.error("Error deleting file:", error);
          throw error;
        }
      })(),
      {
        loading: "Deleting file...",
        success: "File deleted successfully",
        error: (err) => err.message || "Failed to delete file",
      }
    );
  };

  const deleteAdditionalFile = async (fileKey) => {
    toast.promise(
      (async () => {
        try {
          const response = await axiosInstance.delete("/v1/files/delete", {
            params: {
              loan_id: state.initialData.formId,
              step: state.initialData.progressStep,
              part: "part_1_additional",
              key: fileKey,
            },
          });

          if (!response.data.success)
            throw new Error(response.data.message || "Failed to delete file");

          setAdditionalFiles((prevFiles) =>
            prevFiles.filter((file) => file.key !== fileKey)
          );

          dispatch({
            type: "SET_INITIAL_DATA",
            payload: {
              ...state.initialData,
              additionalData: state.initialData.additionalData.filter(
                (file) => file.key !== fileKey
              ),
            },
          });

          return response.data;
        } catch (error) {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "Network error occurred";
          console.error("Error deleting file:", error);
          throw new Error(errorMessage);
        }
      })(),
      {
        loading: "Deleting file...",
        success: "File deleted successfully",
        error: (err) => `Delete failed: ${err.message}`,
      }
    );
  };

  return {
    uploadedFiles,
    fileInputRef,
    handleFileInputChange,
    handleFileDrop,
    removeFile,
    deleteAdditionalFile,
  };
};

export function GuestIndividualLoanTracker() {
  const location = useLocation();
  const navigate = useNavigate();
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  const [state, dispatch] = useReducer(
    reducer,
    useInitialState(location.state)
  );
  const [currentStep, setCurrentStep] = useState(
    state.initialData.progressStep
  );
  const [dataFetched, setDataFetched] = useState(false);
  const [additionalFiles, setAdditionalFiles] = useState(
    state.initialData.additionalData || []
  );

  const {
    uploadedFiles,
    fileInputRef,
    handleFileInputChange,
    handleFileDrop,
    removeFile,
    deleteAdditionalFile,
  } = useFileUpload(state, queryClient, dispatch, setAdditionalFiles);

  const pdfUrl = useMemo(
    () => convertBase64ToPdfUrl(state.initialData.pdfBase64),
    [state.initialData.pdfBase64]
  );

  // Cleanup URLs on unmount
  useEffect(() => {
    return () => {
      if (pdfUrl) URL.revokeObjectURL(pdfUrl);
      uploadedFiles.forEach((file) => URL.revokeObjectURL(file.url));
    };
  }, [pdfUrl, uploadedFiles]);

  const navigateToPage = (page) => {
    if (page) {
      const navigationState = {
        pdfBase64: state.initialData.pdfBase64,
        jsonData: state.initialData.jsonData,
        iframe: state.initialData.iframe,
        formId: state.initialData.formId,
        loanName: state.initialData.loan_name,
        progressStep: state.initialData.progressStep,
        pdfBase64NorCalTemplate: state.initialData.pdfBase64NorCalTemplate,
        docAiResultsNorCalTemplate:
          state.initialData.docAiResultsNorCalTemplate,
      };

      switch (page) {
        case "guest-pdf-viewer":
          navigate("/guest-pdf-viewer", { state: navigationState });
          break;
        case "guest-custom-pdf-viewer-tables":
          navigate("/guest-custom-pdf-viewer-tables", {
            state: {
              pdfBase64: state.initialData.pdfBase64NorCalTemplate,
              jsonData: state.initialData.docAiResultsNorCalTemplate,
              formId: state.initialData.formId,
            },
            replace: true,
          });
          break;
        default:
          console.warn("Unknown action:", page);
          break;
      }
    }
  };

  const fetchAdditionalData = useCallback(
    (id) => {
      if (socket) {
        const fieldsToFetch = [
          "pdfBase64",
          "docAiResults",
          "googleDocsIframe",
          "_id",
          "loan_name",
          "progress_step",
          "pdfBase64NorCalTemplate",
          "docAiResultsNorCalTemplate",
        ];
        socket.emit("get_items", { id, fields: fieldsToFetch });

        const handleItemData = (data) => {
          if (data && data.length > 0) {
            const fetchedData = data[0];
            dispatch({
              type: "SET_INITIAL_DATA",
              payload: {
                pdfBase64: fetchedData.pdfBase64,
                jsonData: fetchedData.docAiResults,
                iframe: fetchedData.googleDocsIframe,
                formId: fetchedData._id,
                progressStep: fetchedData.progress_step,
                pdfBase64NorCalTemplate: fetchedData.pdfBase64NorCalTemplate,
                docAiResultsNorCalTemplate:
                  fetchedData.docAiResultsNorCalTemplate,
                loan_name: fetchedData.loan_name,
              },
            });
            setDataFetched(true);
          }
        };

        socket.on("item", handleItemData);

        return () => {
          socket.off("item", handleItemData);
        };
      } else {
        console.error("[IndividualLoanTracker] Socket is not available");
      }
    },
    [socket]
  );

  // Fetch data on mount if needed
  useEffect(() => {
    if (!dataFetched && state.initialData.formId) {
      fetchAdditionalData(state.initialData.formId);
    }
  }, [state.initialData.formId, dataFetched]);

  // Update steps when current step changes
  useEffect(() => {
    dispatch({
      type: "SET_STEPS",
      payload: state.steps.map((step, index) => ({
        ...step,
        completed: index < currentStep,
      })),
    });
  }, [currentStep]);

  return (
    <div className="min-h-screen bg-[#f4f5f5]">
      <div className="py-4 px-5 max-w-7xl mx-auto">
        <Header
          onBack={() => navigate("/guest-dynamic-table")}
          businessName={state.initialData.loan_name}
          onAnswerQuestions={() =>
            navigate("/guest-additional-questions", {
              state: {
                businessName: state.initialData.loan_name,
                formId: state.initialData.formId,
              },
            })
          }
        />

        <div className="px-5">
          <Worker workerUrl={pdfjsWorker}>
            <div className="flex gap-5 mb-8">
              <PdfViewer
                pdfUrl={pdfUrl}
                onView={() => navigateToPage("guest-pdf-viewer")}
              />
              <FileUploader
                files={uploadedFiles}
                onFileInputChange={handleFileInputChange}
                onFileDrop={handleFileDrop}
                onRemoveFile={removeFile}
                fileInputRef={fileInputRef}
                additionalData={additionalFiles}
                deleteAdditionalFile={deleteAdditionalFile}
              />
            </div>
          </Worker>
        </div>
      </div>
    </div>
  );
}

// Sub-components
function Header({ onBack, businessName, onAnswerQuestions }) {
  return (
    <>
      <div className="flex justify-between items-center mb-6">
        <BackButton onClick={onBack} />
        <AnswerQuestionsButton onClick={onAnswerQuestions} />
      </div>
      <div className="mb-8">
        <h3 className="text-[28px] text-[#363C45] font-medium mb-2">
          {businessName}
        </h3>
        <p className="text-lg text-[#363C45]">Upload Files</p>
      </div>
    </>
  );
}

function PdfViewer({ pdfUrl, onView }) {
  return (
    <div
      onClick={onView}
      className="w-1/2 h-[820px] border-2 border-gray-200 rounded-lg p-4 bg-white flex items-center justify-center cursor-pointer hover:border-[#2183F6] transition-colors duration-200"
    >
      {pdfUrl ? (
        <div className="h-full w-full">
          <Viewer fileUrl={pdfUrl} defaultScale={SpecialZoomLevel.PageWidth} />
        </div>
      ) : (
        <EmptyPdfState />
      )}
    </div>
  );
}

function FileUploader({
  files,
  onFileInputChange,
  onFileDrop,
  onRemoveFile,
  fileInputRef,
  additionalData = [],
  deleteAdditionalFile,
}) {
  const handleClick = (e) => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  return (
    <div
      onClick={handleClick}
      onDragOver={(e) => e.preventDefault()}
      onDrop={onFileDrop}
      className="w-1/2 h-[820px] border-2 border-gray-200 rounded-lg p-4 bg-white"
    >
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        multiple
        accept=".pdf,.doc,.docx,.xls,.xlsx"
        onChange={onFileInputChange}
      />

      {files.length === 0 && additionalData.length === 0 ? (
        <div className="h-full flex items-center justify-center">
          <EmptyUploadState />
        </div>
      ) : (
        <div className="space-y-4">
          {(files.length > 0 || additionalData.length > 0) && (
            <h4 className="text-sm font-medium text-gray-900 mb-2">
              Uploaded Files
            </h4>
          )}

          {files.length > 0 && (
            <div className="space-y-2">
              <FileList files={files} onRemoveFile={onRemoveFile} />
            </div>
          )}

          {additionalData.length > 0 && (
            <div className="space-y-2">
              {additionalData.map((item) => (
                <div
                  key={item.key}
                  className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center gap-2">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                      />
                    </svg>
                    <span className="text-sm text-gray-700 truncate">
                      {item.name}
                    </span>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteAdditionalFile(item.key);
                    }}
                    className="text-red-600 hover:text-red-800 transition flex items-center justify-center p-1 rounded-full hover:bg-red-50"
                  >
                    <img
                      src="/images/Trash.svg"
                      width={16}
                      height={16}
                      alt="Delete"
                      className="w-4 h-4"
                    />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
