import React from "react";

export const EmptyUploadState = () => (
  <div className="text-center">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">
      No files uploaded
    </h3>
    <p className="mt-1 text-sm text-gray-500">
      Click to upload or drag and drop files here
    </p>
  </div>
);

export const FileList = ({ files, onRemoveFile, enableDelete = true }) => (
  <div className="mt-4 space-y-2">
    {files.map((file, index) => (
      <div
        key={index}
        className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
      >
        <div
          className="flex items-center gap-2"
          onClick={(e) => e.stopPropagation()}
        >
          <svg
            className="h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
          <span className="text-sm text-gray-700 truncate">
            {typeof file === "string" ? file : file.fileName || file.file.name}
          </span>
        </div>
        {enableDelete && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onRemoveFile(index);
            }}
            className="p-1 hover:bg-gray-200 rounded-full transition-colors duration-200"
          >
            <img
              src="/images/Trash.svg"
              alt="Delete"
              className="h-4 w-4 text-gray-500"
            />
          </button>
        )}
      </div>
    ))}
  </div>
);

export const EmptyPdfState = () => (
  <div className="text-center">
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
    <h3 className="mt-2 text-sm font-medium text-gray-900">No PDF</h3>
    <p className="mt-1 text-sm text-gray-500">Click to view PDF document</p>
  </div>
);
