// src/layouts/ProtectedLayout.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedLayout = ({ element: Component }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [accessLevel, setAccessLevel] = useState(null);

    useEffect(() => {
        // Get authentication info from localStorage
        const accessToken = localStorage.getItem('accessToken');
        const storedAccessLevel = localStorage.getItem('accessLevel');

        if (accessToken) {
            setIsAuthenticated(true);
            setAccessLevel(storedAccessLevel);

            // Verify token validity with server
            fetch('/api/auth/status', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        // Token invalid - clear and redirect
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('accessLevel');
                        setIsAuthenticated(false);
                        setAccessLevel(null);
                    }
                })
                .catch(error => {
                    console.error('Auth check failed:', error);
                });
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    // Show loading while checking auth
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // Check route access based on level
    const path = window.location.pathname;
    if (accessLevel === 'guest' && !path.startsWith('/guest-')) {
        return <Navigate to="/guest-dynamic-table" />;
    }
    if (accessLevel !== 'guest' && path.startsWith('/guest-')) {
        return <Navigate to="/dynamic-table" />;
    }

    return Component;
};

export default ProtectedLayout;
