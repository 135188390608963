import React from "react";

const Button = ({ onClick, children, variant = "primary", icon, text }) => {
  const baseClasses = "flex items-center gap-2 transition-colors duration-200";
  const variantClasses = {
    primary: "px-4 py-2 bg-[#2183F6] text-white rounded-lg hover:bg-blue-700",
    secondary: "text-[#2183F6] hover:text-blue-700",
  };

  return (
    <button
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant]}`}
    >
      {icon &&
        (typeof icon === "string" ? (
          <img src={icon} alt="" className="w-5 h-5" />
        ) : (
          icon
        ))}
      {text && <span className="font-medium">{text}</span>}
      {children}
    </button>
  );
};

export const BackButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    variant="secondary"
    icon={
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 19L8 12L15 5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
    text="Back"
  />
);

export const AnswerQuestionsButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    icon={
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 9.16667H12.5M7.5 12.5H10M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    }
    text="Answer Questions"
  />
);
