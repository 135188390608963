// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom';
import CustomPDFViewer from './components/CustomPDFViewer';
import CustomPDFViewerTables from './components/CustomPDFViewerTables';
import Auth from './components/Auth';
import FormsDynamicTable from './components/FormsDynamicTable';
import IndividualLoanTracker from './components/IndividualLoanTracker';
import { AuthCallback } from './components/AuthCallback';
import GuestDynamicTable from './components/GuestDynamicTable';
import { GuestIndividualLoanTracker } from './components/GuestIndividualLoanTracker';
import GuestPDFViewer from './components/GuestPDFViewer';
import GuestAnswerAdditionalQuestions from './components/GuestAdditionalQuestions';
import { ThemeProvider } from './context/ThemeContext';
import { GlobalProvider } from './context/GlobalContext';
import { SocketProvider } from './context/SocketContext'; // Import SocketProvider
import ProtectedLayout from './layouts/ProtectedLayout';
import './index.css';
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from './utils/queryClient'
import GuestSignatureEmails from './components/GuestSignatureEmails';


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    console.log('[App] accessToken:', accessToken);
    if (accessToken) {
      setIsAuthenticated(true);
      console.log('[App] isAuthenticated:', isAuthenticated);
    }
  }, []);

  const handleLogin = (status, level) => {
    setIsAuthenticated(status);
    setAccessLevel(level);
  };

  // return (
  //     <GlobalProvider>
  //         <ThemeProvider>
  //             <SocketProvider> {/* SocketProvider moved higher to avoid unmounts */}
  //                 <Router>
  //                     <Routes>
  //                         {/* Public Routes */}
  //                         <Route
  //                             path="/login"
  //                             element={<Auth onLogin={handleLogin} />}
  //                         />
  //                         <Route
  //                             path="/auth-callback"
  //                             element={<AuthCallback onLogin={handleLogin} />}
  //                         />

  //                         {/* Protected Routes */}
  //                         <Route
  //                             element={
  //                                 <ProtectedLayout
  //                                     isAuthenticated={isAuthenticated}
  //                                     accessLevel={accessLevel}
  //                                 />
  //                             }
  //                         >
  //                             <Route
  //                                 path="/pdf-viewer"
  //                                 element={
  //                                     accessLevel !== 'guest' ? <CustomPDFViewer /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                             <Route
  //                                 path="/custom-pdf-viewer-tables"
  //                                 element={
  //                                     accessLevel !== 'guest' ? <CustomPDFViewerTables /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                             <Route
  //                                 path="/dynamic-table"
  //                                 element={
  //                                     accessLevel !== 'guest' ? <FormsDynamicTable /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                             <Route
  //                                 path="/individual-loan-tracker"
  //                                 element={
  //                                     accessLevel !== 'guest' ? <IndividualLoanTracker /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                             <Route
  //                                 path="/guest-pdf-viewer"
  //                                 element={
  //                                     accessLevel === 'guest' ? <GuestPDFViewer /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                             <Route
  //                                 path="/guest-dynamic-table"
  //                                 element={
  //                                     accessLevel === 'guest' ? <GuestDynamicTable /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                             <Route
  //                                 path="/guest-individual-loan-tracker"
  //                                 element={
  //                                     accessLevel === 'guest' ? <GuestIndividualLoanTracker /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                             <Route
  //                                 path="/guest-additional-questions"
  //                                 element={
  //                                     accessLevel === 'guest' ? <GuestAnswerAdditionalQuestions /> : <Navigate to="/login" />
  //                                 }
  //                             />
  //                         </Route>

  //                         {/* Default Route */}
  //                         <Route
  //                             path="/"
  //                             element={<Navigate to="/login" />}
  //                         />
  //                     </Routes>
  //                 </Router>
  //             </SocketProvider>
  //         </ThemeProvider>
  //     </GlobalProvider>
  // );
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <ThemeProvider>
          <SocketProvider>
            <Router>
              <Routes>
                <Route path="/login" element={<Auth onLogin={handleLogin} />} />
                <Route path="/auth-callback" element={<AuthCallback onLogin={handleLogin} />} />
                <Route
                  path="/pdf-viewer"
                  element={
                    <ProtectedLayout element={<CustomPDFViewer />} />
                  }
                />
                <Route
                  path="/custom-pdf-viewer-tables"
                  element={
                    <ProtectedLayout element={<CustomPDFViewerTables />} />
                  }
                />
                <Route
                  path="/dynamic-table"
                  element={
                    <ProtectedLayout element={<FormsDynamicTable />} />
                  }
                />
                <Route
                  path="/individual-loan-tracker"
                  element={
                    <ProtectedLayout element={<IndividualLoanTracker />} />
                  }
                />
                <Route
                  path="/guest-pdf-viewer"
                  element={
                    <ProtectedLayout element={<GuestPDFViewer />} />
                  }
                />
                <Route
                  path="/guest-dynamic-table"
                  element={
                    <ProtectedLayout element={<GuestDynamicTable />} />
                  }
                />
                <Route
                  path="/guest-individual-loan-tracker"
                  element={
                    <ProtectedLayout element={<GuestIndividualLoanTracker />} />
                  }
                />
                <Route
                  path="/guest-additional-questions"
                  element={
                    <ProtectedLayout element={<GuestAnswerAdditionalQuestions />} />
                  }
                />
                <Route
                  path="/guest-signature-emails"
                  element={
                    <ProtectedLayout element={<GuestSignatureEmails />} />
                  }
                />
                <Route path="/" element={<Navigate to="/dynamic-table" />} />
              </Routes>
            </Router>
          </SocketProvider>
        </ThemeProvider>
      </GlobalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
