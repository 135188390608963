import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../context/GlobalContext'

// Constants
const ROUTES = {
  GUEST: '/guest-dynamic-table',
  DEFAULT: '/dynamic-table',
  LOGIN: '/login'
}

export function AuthCallback({ onLogin }) {
  const navigate = useNavigate()
  const { setGlobalUserOrganizationAccessName } = useGlobalContext()

  useEffect(() => {
    const handleAuth = async () => {
      try {
        const params = new URLSearchParams(window.location.search)
        const authData = {
          accessToken: params.get('accessToken'),
          accessLevel: params.get('accessLevel'),
          userOrg: params.get('userOrg')
        }

        // Store auth data
        if (authData.accessToken)
          localStorage.setItem('accessToken', authData.accessToken)
        if (authData.accessLevel)
          localStorage.setItem('accessLevel', authData.accessLevel)
        if (authData.userOrg) {
          localStorage.setItem('userOrg', authData.userOrg)
          setGlobalUserOrganizationAccessName(authData.userOrg)
        }

        const response = await fetch('/api/auth/status', {
          credentials: 'include'
        })

        if (!response.ok) throw new Error('Auth status check failed')

        const finalAccessLevel = response.headers.get('accessLevel') || authData.accessLevel

        if (typeof onLogin === 'function')
          onLogin(true, finalAccessLevel)

        navigate(finalAccessLevel === 'guest' ? ROUTES.GUEST : ROUTES.DEFAULT)

      } catch (error) {
        console.error('Authentication failed:', error)
        navigate(ROUTES.LOGIN)
      }
    }

    handleAuth()
  }, [navigate, onLogin, setGlobalUserOrganizationAccessName])

  return (
    <div className="flex items-center justify-center min-h-screen">
      <h1 className="text-xl font-semibold">Processing Authentication...</h1>
    </div>
  )
}