import { Button } from "@mui/material";
import NorCalLogoHorizontal from "./img/Nor-Cal-Logo-Horizontal.svg"

export default function SiteHeader (props){
    const {awayFromHome} = props;

    const backToHomeBtn = (<Button className="!bg-surface-secondary-white rounded-[0.25rem]">
        <img src="/images/HouseSimple.svg" alt="HomeIcon" />
    </Button>);

    return(
        <div className="w-full bg-white py-[0.75rem] px-[2rem] flex justify-between">
            <img src={NorCalLogoHorizontal} alt="Company-Logo" />
            {awayFromHome === true ? backToHomeBtn : <noscript></noscript>}
        </div>
    )
}