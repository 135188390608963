import React, { createContext, useState, useContext } from 'react';

// Create the context
export const GlobalContext = createContext();

// Create a provider component
export const GlobalProvider = ({ children }) => {
  const [globalUserOrganizationAccessName, setGlobalUserOrganizationAccessName] = useState(null);

  return (
    <GlobalContext.Provider value={{ globalUserOrganizationAccessName, setGlobalUserOrganizationAccessName }}>
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobalContext() {
  const context = useContext(GlobalContext)
  if (!context)
    throw new Error('useGlobalContext must be used within a GlobalProvider')
  return context
}
