import React, { useState, useEffect } from 'react';
import { useSocket } from '../context/SocketContext';
import ReactMarkdown from 'react-markdown'; // 引入 ReactMarkdown 组件

const Chat = ({ onClose }) => {
  const { socket, isConnected } = useSocket();
  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState([]);
  const [streamingBuffer, setStreamingBuffer] = useState(''); // Buffer for streaming tokens

  useEffect(() => {
    if (socket) {
      socket.on('chat_response', handleChatResponse);
      socket.on('chat_complete', handleChatComplete);
      socket.on('chat_error', handleChatError);
      socket.on('file_response', handleFileResponse);
      socket.on('image_response', handleImageResponse);
    }

    return () => {
      if (socket) {
        socket.off('chat_response', handleChatResponse);
        socket.off('chat_complete', handleChatComplete);
        socket.off('chat_error', handleChatError);
        socket.off('file_response', handleFileResponse);
        socket.off('image_response', handleImageResponse);
      }
    };
  }, [socket, isConnected]);

  const handleChatResponse = (data) => {
    const content = data.content;
    console.log('[Chat.js] Received chat_response:', content);

    setStreamingBuffer((prevBuffer) => prevBuffer + content); // Append content to buffer
  };

  const handleChatComplete = () => {
    console.log('[Chat.js] Chat complete received.');

    if (streamingBuffer) {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];

        if (
          updatedMessages.length > 0 &&
          updatedMessages[updatedMessages.length - 1].from === 'bot'
        ) {
          updatedMessages[updatedMessages.length - 1].content += streamingBuffer;
          updatedMessages[updatedMessages.length - 1].complete = true;
        } else {
          updatedMessages.push({
            from: 'bot',
            content: streamingBuffer,
            complete: true,
          });
        }

        return updatedMessages;
      });

      setStreamingBuffer('');
    }
  };

  const handleChatError = (data) => {
    console.error('[Chat.js] Chat error:', data.msg);
  };

  const handleFileResponse = (data) => {
    console.log('File received:', data.file_id);
    const { file_id, file_data, file_type } = data;
    const fileUrl = `data:${file_type};base64,${file_data}`;

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        from: 'bot',
        type: 'file',
        content: fileUrl,
        fileType: file_type,
        complete: true,
      },
    ]);
  };

  const handleImageResponse = (data) => {
    console.log('Image received:', data.file_id);
    const { file_id, image_data, file_type } = data;
    const fileUrl = `data:${file_type};base64,${image_data}`;

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        from: 'bot',
        type: 'image',
        content: fileUrl,
        fileType: file_type,
        complete: true,
      },
    ]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (prompt.trim() === '') return;

    const userMessage = { from: 'user', content: prompt, complete: true };
    setMessages([...messages, userMessage]);

    socket.emit('chat_message', { prompt });
    setPrompt('');
  };

  useEffect(() => {
    if (streamingBuffer) {
      const interval = setInterval(() => {
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessageIndex = updatedMessages.length - 1;

          if (
            lastMessageIndex >= 0 &&
            updatedMessages[lastMessageIndex].from === 'bot'
          ) {
            updatedMessages[lastMessageIndex].content += streamingBuffer[0];
          } else {
            updatedMessages.push({
              from: 'bot',
              content: streamingBuffer[0],
              complete: false,
            });
          }

          setStreamingBuffer((prevBuffer) => prevBuffer.slice(1));
          return updatedMessages;
        });
      }, 20);

      return () => clearInterval(interval);
    }
  }, [streamingBuffer]);

  const renderFormattedContent = (content) => {
    return <ReactMarkdown>{content}</ReactMarkdown>; // 使用 ReactMarkdown 渲染内容
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Modal Overlay */}
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Modal Content */}
      <div className="relative bg-white w-full max-w-3xl mx-auto h-full max-h-[90vh] flex flex-col rounded-lg shadow-lg">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Chat Assistant</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800"
          >
            &#x2715;
          </button>
        </div>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto p-4" id="chat-container">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`mb-4 ${
                message.from === 'user' ? 'text-right' : 'text-left'
              }`}
            >
              <div
                className={`inline-block px-4 py-2 rounded-lg ${
                  message.from === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-800'
                }`}
              >
                {message.type === 'image' ||
                message.fileType?.startsWith('image/') ? (
                  <img
                    src={message.content}
                    alt="Code Interpreter Output"
                    className="max-w-full h-auto"
                  />
                ) : message.type === 'file' ? (
                  <a href={message.content} download={`file_${index}`}>
                    Download File
                  </a>
                ) : (
                  renderFormattedContent(message.content)
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Input */}
        <form
          onSubmit={handleSubmit}
          className="p-4 border-t flex items-center"
        >
          <input
            type="text"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 border rounded-md px-3 py-2 mr-2"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chat;
