export default function Clock({color}){

    const colorVariants = {
        "dull-yellow": "fill-dull-yellow",
    };
    
    return (
        <svg width="16" className={`${colorVariants[color]}`} height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00004C14.6663 4.31814 11.6816 1.33337 7.99967 1.33337C4.31778 1.33337 1.33301 4.31814 1.33301 8.00004C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z" />
        <path d="M10.4731 10.62C10.3864 10.62 10.2998 10.6 10.2198 10.5467L8.1531 9.31338C7.63977 9.00671 7.25977 8.33338 7.25977 7.74005V5.00671C7.25977 4.73338 7.48643 4.50671 7.75977 4.50671C8.0331 4.50671 8.25977 4.73338 8.25977 5.00671V7.74005C8.25977 7.98005 8.45977 8.33338 8.66643 8.45338L10.7331 9.68671C10.9731 9.82671 11.0464 10.1334 10.9064 10.3734C10.8064 10.5334 10.6398 10.62 10.4731 10.62Z" />
        </svg>
    )
}
