import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSocket } from "../context/SocketContext";
import { toast } from "react-hot-toast";

const GuestSignatureEmails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { socket } = useSocket();
  const formId = location.state?.formId;
  const businessName = location.state?.businessName;

  const [formData, setFormData] = useState({
    lenderEmail: "",
    borrowerEmail: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.lenderEmail) {
      newErrors.lenderEmail = "Lender's email is required";
    } else if (!emailRegex.test(formData.lenderEmail)) {
      newErrors.lenderEmail = "Please enter a valid email address";
    }

    if (!formData.borrowerEmail) {
      newErrors.borrowerEmail = "Borrower's email is required";
    } else if (!emailRegex.test(formData.borrowerEmail)) {
      newErrors.borrowerEmail = "Please enter a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    if (socket && formId) {
      const updatedItem = {
        _id: formId,
        progress_step: 12,
        signature_emails: formData,
      };

      socket.emit("update_items", { items: [updatedItem] }, (response) => {
        if (response.success) {
          toast.success("Emails submitted successfully");
          navigate("/guest-dynamic-table");
        } else {
          toast.error("Failed to submit emails");
        }
        setIsSubmitting(false);
      });
    } else {
      console.error("[GuestSignatureEmails] Socket or formId is not available");
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 transition-colors mb-8"
        >
          <img src="/images/ArrowLeft.svg" className="w-4 h-4" alt="Back" />
          <span>Back</span>
        </button>

        <div className="bg-white rounded-lg shadow-sm p-6 sm:p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">
            Signature Emails for {businessName}
          </h1>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Lender's Signature Email
                </label>
                <input
                  type="email"
                  name="lenderEmail"
                  value={formData.lenderEmail}
                  onChange={handleChange}
                  className={`w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                    errors.lenderEmail ? "border-red-500" : "border-gray-300"
                  }`}
                  placeholder="Enter lender's email"
                />
                {errors.lenderEmail && (
                  <p className="mt-1 text-sm text-red-600">{errors.lenderEmail}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Borrower's Signature Email
                </label>
                <input
                  type="email"
                  name="borrowerEmail"
                  value={formData.borrowerEmail}
                  onChange={handleChange}
                  className={`w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${
                    errors.borrowerEmail ? "border-red-500" : "border-gray-300"
                  }`}
                  placeholder="Enter borrower's email"
                />
                {errors.borrowerEmail && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.borrowerEmail}
                  </p>
                )}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full sm:w-auto px-6 py-3 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors
                ${
                  isSubmitting
                    ? "bg-blue-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                }`}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GuestSignatureEmails;